@import "_variables";

.root {
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  background: linear-gradient(
      320deg,
      rgba(92, 235, 52, 0.3),
      rgba(92, 235, 52, 0)
    ),
    linear-gradient(180deg, #27a4d6 0%, #1a6d8e 100%);

  @include breakpoint-mobile {
    flex-direction: column-reverse;
    border-radius: 12px;
    margin: 0 -4vw;
  }
}

.left {
  padding: 75px 0 75px 75px;

  @include breakpoint-mobile {
    padding: 5vw 10vw 10vw 10vw;
  }
  @include breakpoint-tablet {
    padding: 5vw 0 10vw 10vw;
  }
}

.right {
  padding: 75px 75px 75px 37.5px;

  @include breakpoint-mobile {
    flex-direction: column;
    padding: 10vw 10vw 0 10vw;
  }
  @include breakpoint-tablet {
    flex-direction: column;
    padding: 10vw 10vw 0 10vw;
  }
}

.figure {
  flex: 2;
}

.children {
  flex: 1;
  text-align: left;
}
