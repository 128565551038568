@import "_variables.scss";

.cards {
  display: flex;
  min-height: 600px;
  overflow: visible;
}

.slideshow {
  animation-duration: 50s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: moveLeft;
}

.row {
  display: flex;

  &:nth-child(even) {
    margin-left: -130px;
  }
}

@keyframes moveLeft {
  from {
    transform: translateX(-33.33%);
  }
  to {
    transform: translateX(-66.66%);
  }
}
