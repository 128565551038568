// Small tablets and large smartphones (landscape view)
$screen-mobile-min: 576px;

// Small tablets (portrait view)
$screen-tablet-min: 768px;

// Tablets and small desktops
$screen-desktop-min: 992px;

@mixin breakpoint-mobile {
  @media (max-width: #{$screen-tablet-min}) {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media (min-width: #{$screen-tablet-min}) and (max-width: #{$screen-desktop-min}) {
    @content;
  }
}

@mixin breakpoint-desktop {
  @media (min-width: #{$screen-desktop-min}) {
    @content;
  }
}
