@import "_variables.scss";

body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
}

.app {
  position: relative;
  text-align: center;
  background: linear-gradient(to top right, $color_navy_blue, $color_elm);
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $color_white;
}

.main {
  z-index: 1;
}

.logo {
  max-height: 200px;
  margin: 60px;
  user-select: none;

  @media only screen and (max-width: 800px) {
    max-height: 150px;
  }
}

a {
  color: $color_white;
  text-decoration: none;
}
