@import "_variables.scss";

.button {
  margin: var(--space-xxs);
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  font: inherit;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.5px;
  transition: all 250ms ease;
  user-select: none;
}

.light {
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 0.85);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
  }
}

.dark {
  color: #173681;
  border: 2px solid #173681;

  &:hover {
    background-color: #173681;
  }
}

.green {
  color: #fff;
  background-color: #264653;
  border: 2px solid #264653;

  &:hover {
    background-color: #336b7e;
    border-color: #336b7e;
  }
}

.loading {
  pointer-events: none;
  opacity: 0.5;
}
