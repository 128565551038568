@import "_variables";

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 15px;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  user-select: none;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.navLink {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-right: 13px;
  transition: color 400ms ease;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}

.activeLink {
  color: rgba(255, 255, 255, 1);
}

.actionButton {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.75px;
  margin-left: 13px;
  background-color: #1b487f;
  padding: 6px 12px;
  border-radius: 50px;
  transition: filter 200ms ease 0s;

  &:hover {
    filter: brightness(120%);
  }
}

.right {
  display: flex;
  margin-left: auto;

  & > * {
    margin-left: 20px !important;
  }
}
