@import "_variables.scss";

.h2 {
  @include h2;
  color: rgb(23, 51, 59);
}

.p {
  @include p;
  margin-bottom: var(--space-xl);
}

.pSmall {
  @include p-sm;
  margin-top: var(--space-md);
  color: rgba(255, 255, 255, 0.8);
}

.figure {
  & svg {
    width: 100%;
  }
}

.link {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline;
  transition: color 400ms ease;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}
