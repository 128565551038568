@import "_breakpoints.scss";

:root {
  --space-unit: 1em;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
}

.responsiveSpacing {
  :root {
    @include breakpoint-mobile {
      --space-unit: 1.25em;
    }
    @include breakpoint-tablet {
      --space-unit: 2em;
    }
    @include breakpoint-desktop {
      --space-unit: 3em;
    }
  }
}
