@import "_variables.scss";

.card {
  display: inline-flex;
  flex-direction: column;
  color: $color_white;
  background-color: $color_cloud_burst;
  width: 200px;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0px 38px 95px 0px rgba(0, 0, 0, 0.25);

  @include breakpoint-mobile {
    width: 150px;

    .img {
      height: 35px;
    }

    .title {
      font-size: 16px;
    }
    
  }
}

.title {
  margin: 0;
  font-size: 18px;
}

.img {
  height: 50px;
  margin-bottom: 10px;
}
