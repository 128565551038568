@import "_variables.scss";

.container {
  padding: 100px 50px 50px 50px;
  overflow: hidden;
  @include breakpoint-mobile {
    padding: 12vw 8vw 2vw 8vw;
  }
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.heading {
  text-align: left;
  max-width: 800px;
  margin-bottom: 60px;
}

.h1 {
  @include h1;
}

.subHeading {
  @include subHeading;
}

.lightGreyBg {
  background-color: $color_light_gray;

  .h1 {
    color: rgb(15, 15, 15);
  }

  .subHeading {
    color: rgb(125, 125, 125);
  }
}

.darkGreyBg {
  background-color: $color_dark_gray;

  .h1 {
    color: rgb(15, 15, 15);
  }

  .subHeading {
    color: rgb(125, 125, 125);
  }
}

.gradientGreenBg {
  background: radial-gradient(
    ellipse at center,
    rgba(255, 254, 234, 1) 0%,
    rgba(255, 254, 234, 1) 35%,
    #b7e8eb 100%
  );

  .h1 {
    color: #264653;
  }

  .subHeading {
    color: rgb(125, 125, 125);
  }
}

.darkBlueBg {
  background-color: $color_cloud_burst;
}

.transparentBg {
  background-color: transparent;
}

.row {
  display: flex;
  flex-direction: row;

  & > div {
    flex: 1;

    &:first-child {
      margin-right: var(--space-md);
    }
    &:last-child {
      margin-left: var(--space-md);
    }
  }

  @include breakpoint-mobile {
    flex-direction: column;

    & > div {
      margin: 0 !important;
    }

    &.textLeft {
      flex-direction: column-reverse;
    }
  }
}

.marginRight {
  margin-right: var(--space-xxl);
}
.marginLeft {
  margin-left: var(--space-xxl);
}
