@import "_breakpoints.scss";

@mixin h1 {
  font-weight: 700;
  font-size: 64px;
  margin-bottom: 1em;
  letter-spacing: 1px;
  line-height: 1.03125em;
  margin: 0;
  margin-bottom: var(--space-xs);

  @include breakpoint-mobile {
    font-size: 10vw;
  }
}

@mixin h2 {
  font-size: 35px;
  font-weight: 700;
  letter-spacing: -0.39px;
  line-height: 42px;
  margin-top: 0;
  margin-bottom: var(--space-xs);
}

@mixin subHeading {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.45px;
  line-height: 1.25;
  margin: 0;
  margin-bottom: var(--space-xs);

  @include breakpoint-mobile {
    font-size: 5vw;
  }
}

@mixin p {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 26px;
}

@mixin p-sm {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 22px;
}
