@import "_variables";

.logo {
  height: 20px;

  @include breakpoint-mobile {
    display: none;
  }
}

.onlyCloudLogo {
  height: 20px;
  display: none;

  @include breakpoint-mobile {
    display: inline-block;
  }
}

.light {
  & path {
    fill: #fff;
  }
}

.dark {
  & path {
    fill: rgba(0, 0, 0, 0.5);
  }
}
