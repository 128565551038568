@import "_variables";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  width: 100%;
  max-width: 200px;
  min-height: 400px;
  margin: 40px 50px;
  box-shadow: rgba(0, 0, 0, 0.05) -4px 9px 25px -6px;
  z-index: 2;

  @include breakpoint-mobile {
    margin: 40px 10px;
  }
}

.img {
  margin-top: -70px;
}

.title {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 700;
}

.subTitle {
  margin: 0 20px;
  color: #6f6f6f;
  font-size: 16px;
  letter-spacing: 1px;
}

.iconButton {
  margin-top: 30px;
  margin-bottom: 20px;
}
