@import "_variables";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 20px;
  display: flex;

  @include breakpoint-mobile {
    padding: 10px;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.headerBackground {
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px) brightness(125%);
  transition: opacity 200ms ease-in-out 0s;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 0px 0px;
}

.logoContainer {
  margin-right: auto;
}

.logo {
  padding-right: 10px;
  height: 20px;
}

.text {
  fill: #fff;
}

.links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
}

.navLink {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0 13px;
  transition: color 400ms ease;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }
}

.activeLink {
  color: rgba(255, 255, 255, 1);
}

.actionButton {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.75px;
  margin-left: 13px;
  background-color: #1b487f;
  padding: 6px 12px;
  border-radius: 50px;
  transition: filter 200ms ease 0s;
  cursor: pointer;
  &:hover {
    filter: brightness(120%);
  }
}

.hasScrolled {
  .navLink {
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }

  .activeLink {
    color: rgba(0, 0, 0, 1);
  }

  .headerBackground {
    opacity: 1;
  }
}
