@import "_variables.scss";

.container {
  color: rgb(15, 15, 15);
}

.cardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
}
