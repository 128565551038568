@import "_variables.scss";

.iconButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  background-color: #2A9D8F;
  border-radius: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color ease-out 100ms;

  &:hover {
    background-color: #00CA71;
  }
}

.animation {
  position: absolute;
  display: none;
}
.showAnimation {
  display: block;
}
