@import "_variables.scss";

.h2 {
  @include h2;
  color: rgb(23, 51, 59);
}

.p {
  @include p;
  margin-bottom: var(--space-xl);
}

.pSmall {
  @include p-sm;
  margin-top: var(--space-md);
  color: rgba(255, 255, 255, 0.8);
}

.video {
  margin-right: 20px;
}
