.intro {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100vh;
  min-height: 750px;
}

.logo {
  width: 100%;
  max-width: 500px;
}
