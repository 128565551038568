@import "_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contactUsFormContainer {
  width: 30vw;
  padding: var(--space-sm);
  position: relative;
}
.formTitle {
  font-weight: 300;
  text-align: center;
}
.contactUsSvg {
  width: 30vw;
}
.contactUsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.btnRow {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  width: 100%;
}
.inputField {
  margin: var(--space-xs);
  padding: var(--space-sm);
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 5px #00000005;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font: inherit;
  font-size: 15px;

  transition: all 200ms ease-out;
  &:focus-visible {
    box-shadow: 0 0 10px #00000020;
    transform: scale(1.04);
  }
}
.error {
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: right;
  margin-bottom: var(--space-xs);
}

.tyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;

  left: 50%;
  width: 100%;

  transform: translate(-50%, -50%);
  transition: all 0.4s;
}
.tyTitle {
  text-align: center;
  font-size: 30px;
  width: 100%;
}
.tySubtitle {
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  width: 100%;
}

.hidden {
  pointer-events: none;
  opacity: 0;
}
a.closeBtn {
  display: none;

  @include breakpoint-mobile {
    display: block;
  }
}

@include breakpoint-mobile {
  .container {
    flex-direction: column;
  }
  .contactUsFormContainer {
    width: 80vw;
    padding: var(--space-xs);
  }

  .contactUsSvg {
    margin-top: 20px;
    width: 30vh;
  }
}
